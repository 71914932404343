@font-face {
    font-family: 'PP Eiko';
    src: url('PPEiko-Thin.eot');
    src: url('PPEiko-Thin.eot?#iefix') format('embedded-opentype'),
        url('PPEiko-Thin.woff2') format('woff2'),
        url('PPEiko-Thin.woff') format('woff'),
        url('PPEiko-Thin.ttf') format('truetype'),
        url('PPEiko-Thin.svg#PPEiko-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

