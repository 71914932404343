@font-face {
    font-family: 'PP Eiko';
    src: url('PPEiko-Black.eot');
    src: url('PPEiko-Black.eot?#iefix') format('embedded-opentype'),
        url('PPEiko-Black.woff2') format('woff2'),
        url('PPEiko-Black.woff') format('woff'),
        url('PPEiko-Black.ttf') format('truetype'),
        url('PPEiko-Black.svg#PPEiko-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

