@font-face {
    font-family: 'PP Eiko';
    src: url('PPEiko-LightItalic.eot');
    src: url('PPEiko-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('PPEiko-LightItalic.woff2') format('woff2'),
        url('PPEiko-LightItalic.woff') format('woff'),
        url('PPEiko-LightItalic.ttf') format('truetype'),
        url('PPEiko-LightItalic.svg#PPEiko-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

