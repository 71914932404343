@font-face {
    font-family: 'PP Eiko';
    src: url('PPEiko-BlackItalic.eot');
    src: url('PPEiko-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('PPEiko-BlackItalic.woff2') format('woff2'),
        url('PPEiko-BlackItalic.woff') format('woff'),
        url('PPEiko-BlackItalic.ttf') format('truetype'),
        url('PPEiko-BlackItalic.svg#PPEiko-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

