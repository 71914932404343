@font-face {
    font-family: 'PP Eiko';
    src: url('PPEiko-Light.eot');
    src: url('PPEiko-Light.eot?#iefix') format('embedded-opentype'),
        url('PPEiko-Light.woff2') format('woff2'),
        url('PPEiko-Light.woff') format('woff'),
        url('PPEiko-Light.ttf') format('truetype'),
        url('PPEiko-Light.svg#PPEiko-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

