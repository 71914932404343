@font-face {
    font-family: 'PP Eiko';
    src: url('PPEiko-ThinItalic.eot');
    src: url('PPEiko-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('PPEiko-ThinItalic.woff2') format('woff2'),
        url('PPEiko-ThinItalic.woff') format('woff'),
        url('PPEiko-ThinItalic.ttf') format('truetype'),
        url('PPEiko-ThinItalic.svg#PPEiko-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

