@font-face {
    font-family: 'Aeonik TRIAL';
    src: url('AeonikTRIAL-Light.eot');
    src: url('AeonikTRIAL-Light.eot?#iefix') format('embedded-opentype'),
        url('AeonikTRIAL-Light.woff2') format('woff2'),
        url('AeonikTRIAL-Light.woff') format('woff'),
        url('AeonikTRIAL-Light.ttf') format('truetype'),
        url('AeonikTRIAL-Light.svg#AeonikTRIAL-Light') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

